import React, { useState, useEffect } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const CookieBanner = (props) => {
  var fields = props.fields;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookieAccepted');
    if (!isAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-banner">
      <button className="close-button" onClick={handleClose}>
        {'×'}
      </button>
      <Text tag="h2" field={fields.Title} />
      <RichText tag="p" field={fields.Description} />
      <div className="button-group">
        <button onClick={handleAccept}>
          <Text field={fields.AcceptButtonLabel} />
        </button>
        <button onClick={handleClose}>
          <Text field={fields.CloseButtonLabel} />
        </button>
      </div>
    </div>
  );
};

export default withDatasourceCheck()(CookieBanner);
