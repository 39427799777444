// src/components/ImageComponent.js
import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const ImageComponent = ({ fields }) => {
  return (
    <div className="image-component">
      {fields?.Image && <Image field={fields?.Image} className="responsive-image" />}
    </div>
  );
};

export default ImageComponent;
